@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  font-family: Poppins, sans-serif;
}
.react-pdf__Page {  background-color: green; }



/* For pdf */
.react-pdf__Page {
  margin-top: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  box-shadow: 5px 5px 5px 1px #ccc;
  border-radius: 5px;
}

.react-pdf__Page__annotations.annotationLayer {
  padding: 20px;
}

.react-pdf__Page__canvas {
  margin: 0 auto;
}
